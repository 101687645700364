*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --primaryColor: rgb(99, 184, 233);
  --mainWhite: #fff;
  --offWhite: #f7f7f7;
  --mainBlack: #222;
  --mainGrey: #ececec;
  --darkGrey: #cfcfcf;
  --mainTransition: all 0.3s linear;
  --mainSpacing: 3px;
  --lightShadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.5);
  --darkShadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);
}
/* globals */
body {
  padding-top: 66px;
  color: var(--mainBlack);
  background: var(--mainWhite);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  line-height: 1.4;
}
h1 {
  font-size: 3em;
  line-height: 1;
  margin-bottom: 0.5em;
}
h2 {
  font-size: 2em;
  margin-bottom: 0.75em;
}
h3 {
  font-size: 1.5em;
  line-height: 1;
  margin-bottom: 1em;
}
h4 {
  font-size: 1.2em;
  line-height: 1.25;
  margin-bottom: 1.25em;
}
h5 {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1.5em;
}
h6 {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1.5em;
}

.btn-primary {
  display: inline-block;
  text-decoration: none;
  letter-spacing: var(--mainSpacing);
  color: var(--mainBlack);
  background: var(--primaryColor);
  padding: 0.4rem 0.9rem;
  border: 3px solid var(--primaryColor);
  transition: var(--mainTransition);
  text-transform: uppercase;
  cursor: pointer;
}
.btn-primary:hover {
  background: transparent;
  color: var(--primaryColor);
}
.loading {
  text-transform: capitalize;
  text-align: center;
  margin-top: 3rem;
}
.error {
  text-align: center;
  text-transform: uppercase;
  margin: 2rem 0;
}
.empty-search {
  text-align: center;
  text-transform: capitalize;
  margin: 2rem 0;
  padding: 1rem;
  letter-spacing: var(--mainSpacing);
}
/* end of globals */
/* Navbar */
.navbar {
  position: fixed;
  overflow: hidden;
  top: 0;
  left: auto;
  right: auto;
  width: 100%;
  padding: 0.75rem 2rem;
  background: var(--offWhite);
  /* handles Overlapping on the Page */
  z-index: 3;
}

.nav-header {
  display: flex;
  justify-content: space-between;
}
.nav-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}
.nav-icon {
  font-size: 1.5rem;
  color: var(--primaryColor);
}
.nav-links {
  height: 0;
  overflow: hidden;
  transition: var(--mainTransition);
  list-style-type: none;
}
.nav-links a {
  display: block;
  text-decoration: none;
  padding: 1rem 0;
  color: var(--mainBlack);
  transition: var(--mainTransition);
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: var(--mainSpacing);
}
.nav-links a:hover {
  color: var(--primaryColor);
}

.show-nav {
  height: 100px;
}
@media screen and (min-width: 768px) {
  .nav-btn {
    display: none;
  }
  .nav-center {
    max-width: 1170px;
    margin: 0 auto;
    display: flex;
  }
  .nav-links {
    height: auto;
    display: flex;
    margin-left: 0rem;
  }
  .nav-links a {
    margin: 0 1rem;
    padding: 0.5rem 0;
  }
}

/* Hero */
.defaultHero,
.roomsHero {
  min-height: calc(100vh - 66px);
  background: url("./images/Node-Entry.jpg") center/cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.roomsHero {
  background-image: url("./images/Node-Entry.jpg");
  min-height: 60vh;
}
/* End of Hero */
/* Banner */
.banner {
  display: inline-block;
  background: rgba(0, 0, 0, 0.5);
  color: var(--mainWhite);
  padding: 2rem 1rem;
  text-align: center;
  text-transform: capitalize;
  letter-spacing: var(--mainSpacing);
  margin-left: auto;
  margin-right: auto;
}
.banner h1 {
  font-size: 2.5rem;
}

.banner h4 {
  font-size: 2rem;
  text-align: center;
}

.banner div {
  width: 10rem;
  height: 5px;
  background: var(--primaryColor);
  margin: 1.7rem auto;
}
.banner p {
  font-size: 1rem;
  margin-bottom: 2rem;
}
@media screen and (min-width: 576px) {
  .banner {
    padding: 2rem 3rem;
  }
  .banner h1 {
    font-size: 3rem;
  }

  .banner h4 {
    font-size: 2rem;
    text-align: center;
  }
}
@media screen and (min-width: 992px) {
  .banner {
    padding: 2rem 6rem;
  }
  .banner h1 {
    font-size: 4rem;
  }
}
/* End of Banner */

/* About */
/*  global classes */

.btn {
  text-transform: uppercase;
  background: transparent;
  color: var(--clr-black);
  padding: 0.375rem 0.75rem;
  letter-spacing: var(--spacing);
  display: inline-block;
  transition: var(--transition);
  font-size: 0.875rem;
  border: 2px solid var(--clr-black);
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: var(--radius);
}
.btn:hover {
  color: var(--clr-white);
  background: var(--clr-black);
}
/* section */
.section {
  padding: 5rem 0;
}

.section-center {
  width: 90vw;
  margin: 0 auto;
  max-width: 1170px;
}
@media screen and (min-width: 992px) {
  .section-center {
    width: 95vw;
  }
}
main {
  min-height: 100vh;
  display: grid;
  place-items: center;
}
/*
=============== 
About
===============
*/
.title {
  text-align: center;
  margin-bottom: 4rem;
}
.title p {
  width: 80%;
  margin: 0 auto;
}

.about-img {
  margin-bottom: 2rem;
}
.about-img img {
  border-radius: var(--radius);
  object-fit: cover;
  height: 20rem;
}
@media screen and (min-width: 992px) {
  .about-img {
    margin-bottom: 0;
  }

  .about-center {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
  }
}

/*.about {
  background: var(--clr-white);
  border-radius: var(--radius);
  display: grid;
  grid-template-rows: auto 1fr;
}
.btn-container {
  border-top-left-radius: var(--radius);
  border-top-right-radius: var(--radius);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.tab-btn:nth-child(1) {
  border-top-left-radius: var(--radius);
}
.tab-btn:nth-child(3) {
  border-top-right-radius: var(--radius);
}
.tab-btn {
  padding: 1rem 0;
  border: none;
  text-transform: capitalize;
  font-size: 1rem;
  display: block;
  background: var(--clr-grey-9);
  cursor: pointer;
  transition: var(--transition);
  letter-spacing: var(--spacing);
}
.tab-btn:hover:not(.active) {
  background: var(--clr-primary-10);
  color: var(--clr-primary-5);
}
.about-content {
  border-bottom-left-radius: var(--radius);
  border-bottom-right-radius: var(--radius);
  padding: 2rem 1.5rem;
}

*/
/* hide content */
.content {
  display: none;
}
.tab-btn.active {
  background: var(--clr-white);
}
.content.active {
  display: block;
}
  /* End of About */


  /* Features */

  .features{
        text-align: center;
    display: flex;
    border-radius: 20px;
    margin-bottom: 10px;
    z-index: 2;
  }


.features__banner{
  background-color: rgb(248, 248, 248);
  padding: 20px;
  margin-bottom: 120px;
  margin-top: 80px;
  margin-right: 50px;
  margin-left: 650px;

  z-index: 2;
  text-align: center;
}

.Right-section{
  padding: 20px;
  margin-bottom: 40px;
  z-index: 1;
  margin-left: 50px;
  position: absolute;
}


.images{
  width: 350px;
}


/* End of Features */

/* Start of Titile */
.section-title {
  text-align: center;
  margin-bottom: 4rem;
}
.section-title h4 {
  font-size: 2rem;
  letter-spacing: var(--mainSpacing);
  text-transform: capitalize;
  margin-bottom: 1rem;
}
.section-title div {
  width: 5rem;
  height: 5px;
  margin: 0 auto;
  background: var(--primaryColor);
}
/* end of Title */

/* Amenities */

.amenities{
  text-align: center;

  border-radius: 20px;
  margin-bottom: 10px;
  z-index: 2;
}

.amenities-banner{
  background-color: rgb(248, 248, 248);
  padding: 20px;
  margin-bottom: 120px;
  margin-top: 80px;

  width: 100%;


  z-index: 2;
  text-align: center;
}



.Logo{
width: 100px;
object-fit: contain;
margin-left: 10px;
margin-right: 100px;


}


footer {
  padding-top: 48px;
  margin-bottom: 48px;
  color: #303030;
  font-size: 14px;
  text-align: center;
  position: relative;
}

footer a, footer a:visited { color: #525252; }
footer a:hover, footer a:focus { color: darkgray; }

/* copyright */
footer .copyright {
   margin: 0;
   padding: 0;
}
footer .copyright li {
   display: inline-block;
   margin: 0;
   padding: 0;
   line-height: 24px;
}
.ie footer .copyright li {
  display: inline;
}
footer .copyright li:before {
   content: "\2022";
   padding-left: 10px;
   padding-right: 10px;
   color: #095153;
}
footer .copyright  li:first-child:before {
   display: none;
}

.space{
  margin-top: 100px;
  margin-bottom: 100px;
}

.space2{
  margin-top: 0px;
  margin-bottom: 20px;
}

.space3{
  margin-top: 0px;
  margin-bottom: 20px;
}

.icon{
  transform: scale(3.0);
}

.strong{
  font-size: 30px;
  font-family: 'Courier New', Courier, monospace;
}



.left-header{
  text-align: left;
}

.left-p{
  text-align: left;
}

.center{
  text-align: center;






}













.btn {
  text-transform: uppercase;
  background: transparent;
  color: var(--clr-black);
  padding: 0.375rem 0.75rem;
  letter-spacing: var(--spacing);
  display: inline-block;
  transition: var(--transition);
  font-size: 0.875rem;
  border: 2px solid var(--clr-black);
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: var(--radius);
}
.btn:hover {
  color: var(--clr-white);
  background: var(--clr-black);
}
/* section */
.section {
  padding: 5rem 0;
}

.section-center {
  width: 90vw;
  margin: 0 auto;
  max-width: 1170px;
}
@media screen and (min-width: 992px) {
  .section-center {
    width: 95vw;
  }
}
main {
  min-height: 100vh;
  display: grid;
  place-items: center;
}
/*
=============== 
About
===============
*/
.title {
  text-align: center;
  margin-bottom: 4rem;
}
.title p {
  width: 80%;
  margin: 0 auto;
}

.about-img {
  margin-bottom: 2rem;
}
.about-img img {
  border-radius: var(--radius);
  object-fit: cover;
  height: 20rem;
}
@media screen and (min-width: 992px) {
  .about-img {
    margin-bottom: 0;
  }

  .about-center {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
  }
}
.about {
  background: var(--clr-white);
  border-radius: var(--radius);
  display: grid;
  grid-template-rows: auto 1fr;
}
.btn-container {
  border-top-left-radius: var(--radius);
  border-top-right-radius: var(--radius);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.tab-btn:nth-child(1) {
  border-top-left-radius: var(--radius);
}
.tab-btn:nth-child(3) {
  border-top-right-radius: var(--radius);
}
.tab-btn {
  padding: 1rem 0;
  border: none;
  text-transform: capitalize;
  font-size: 1rem;
  display: block;
  background: var(--clr-grey-9);
  cursor: pointer;
  transition: var(--transition);
  letter-spacing: var(--spacing);
}
.tab-btn:hover:not(.active) {
  background: var(--clr-primary-10);
  color: var(--clr-primary-5);
}
.about-content {
  border-bottom-left-radius: var(--radius);
  border-bottom-right-radius: var(--radius);
  padding: 2rem 1.5rem;
}
/* hide content */
.content {
  display: none;
}
.tab-btn.active {
  background: var(--clr-white);
}
.content.active {
  display: block;
}


.grid-title{
  text-align: center;
}

.grid-text{
  text-align: center;
}


.suitesbanner{
  background-color: rgb(248, 248, 248);
  padding: 20px;
  margin-bottom: 120px;
  margin-top: 80px;

  width: 100%;


  z-index: 2;
  text-align: center;
}

.line{
  border-bottom: 4px solid #ccc;
  display: block;

    margin-top: 0px;
    margin-bottom: 20px;
  
}

